@font-face {
  font-family: 'fontello';
  src: url('../../font/fontello.eot?1107439');
  src: url('../../font/fontello.eot?1107439#iefix') format('embedded-opentype'),
  url('../../font/fontello.woff2?1107439') format('woff2'),
  url('../../font/fontello.woff?1107439') format('woff'),
  url('../../font/fontello.ttf?1107439') format('truetype'),
  url('../../font/fontello.svg?1107439#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?1107439#fontello') format('svg');
  }
}
*/

:global {

  [class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }

  .icon-user:before {
    content: '\e800';
  }
  /* '' */
  .icon-clock:before {
    content: '\e801';
  }
  /* '' */
  .icon-cloud-sun:before {
    content: '\e802';
  }
  /* '' */
  .icon-chart-line:before {
    content: '\e803';
  }
  /* '' */
  .icon-arrows-cw:before {
    content: '\e804';
  }
  /* '' */
  .icon-calc:before {
    content: '\e805';
  }
  /* '' */
  .icon-cogs:before {
    content: '\e806';
  }
  /* '' */
  .icon-book:before {
    content: '\e807';
  }
  /* '' */
  .icon-wristwatch:before {
    content: '\e808';
  }
  /* '' */
  .icon-hourglass:before {
    content: '\e809';
  }
  /* '' */
  .icon-calendar:before {
    content: '\e80a';
  }
  /* '' */
  .icon-mail-alt:before {
    content: '\e80b';
  }
  /* '' */
  .icon-doc-text:before {
    content: '\e80c';
  }
  /* '' */
  .icon-phone:before {
    content: '\e80d';
  }
  /* '' */
  .icon-comment:before {
    content: '\e80e';
  }
  /* '' */
  .icon-facebook:before {
    content: '\e80f';
  }
  /* '' */
  .icon-language:before {
    content: '\e810';
  }
  /* '' */
  .icon-bell-alt:before {
    content: '\e811';
  }
  /* '' */
  .icon-location:before {
    content: '\e812';
  }
  /* '' */
  .icon-soccer-ball:before {
    content: '\e813';
  }
  /* '' */
  .icon-flight:before {
    content: '\e814';
  }
  /* '' */
  .icon-globe:before {
    content: '\e815';
  }
  /* '' */
  .icon-video:before {
    content: '\e816';
  }
  /* '' */
  .icon-emo-happy:before {
    content: '\e817';
  }
  /* '' */
  .icon-music:before {
    content: '\e818';
  }
  /* '' */
  .icon-menu:before {
    content: '\e819';
  }
  /* '' */
  .icon-search:before {
    content: '\e81a';
  }
  /* '' */
  .icon-info:before {
    content: '\e81b';
  }
  /* '' */
  .icon-arrow-left:before {
    content: '\e81c';
  }
  /* '' */
  .icon-frown:before {
    content: '\e81d';
  }
  /* '' */
  .icon-cancel:before {
    content: '\e81e';
  }
  /* '' */
  .icon-medium:before {
    content: '\e81f';
  }
  /* '' */
  .icon-twitter:before {
    content: '\e820';
  }
  /* '' */
  .icon-youtube:before {
    content: '\e821';
  }
  /* '' */
  .icon-linkedin:before {
    content: '\e822';
  }
  /* '' */
  .icon-github:before {
    content: '\e823';
  }
  /* '' */
  .icon-heart:before {
    content: '\e824';
  }
  /* '' */
  .icon-instagram:before {
    content: '\e825';
  }
  /* '' */

}


