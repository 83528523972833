:global {
  .fadein {
    &-enter {
      opacity: 0.01;
      &.fadein-enter-active {
        opacity: 1;
        transition: opacity 500ms ease-in;
      }
    }
  }

  .phrase--flashed {
    .phrase__variable {
      background-color: rgb(228, 228, 228) !important;
      transform: scale(1.07);
    }
  }
}

